const useBonusesData = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const { data: bonusesData, execute: refreshBonusesData } = useAsyncFetch({
		path: "/rest/bonus-offer/get-bonuses/",
		method: "get",
		options: {
			immediate,
			cached: true
		}
	});

	return { bonusesData, refreshBonusesData };
};

export default useBonusesData;
